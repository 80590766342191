











import { View } from 'client-website-ts-library/plugins';
import { Component, Mixins } from 'vue-property-decorator';

import Form from '../components/Forms/Form.vue';

@Component({
  components: {
    Form,
  },
})
export default class SalesApparaisal extends Mixins(View) {
}
